import React, { Component } from "react"
import galleryIcon from "../images/menu_gallery.png"
import albumIcon from "../images/menu_gallery_2.png"
// import albumIcon from "../images/menu_album.png"
import './view-selector.css'

class ViewSelector extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false

    this.state = {
      galleryMode: props.galleryMode
    }
  }

  async componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    if (nextProps.galleryMode !== previousState.galleryMode) {
      return {
        galleryMode: nextProps.galleryMode
      }
    }
    return null
  }

  render() {
    if (!this._isMounted || this.props.pageIsLoading) return null


    return <div className="view-selector">
        <button onClick={this.props.handleToggleGalleryMode} className={this.state.galleryMode ? 'selected' : ''} disabled={this.state.galleryMode}>
          <img src={galleryIcon} alt='Mode Gallerie' />
        </button>
        <button onClick={this.props.handleToggleGalleryMode} className={!this.state.galleryMode ? 'selected' : ''} disabled={!this.state.galleryMode}>
          <img src={albumIcon} alt='Mode Polaroid' />
        </button>
      </div>
  }
}

export default ViewSelector
