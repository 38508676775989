import React from 'react';
import videojs from 'video.js'
import EventService from '../services/EventService'
import 'video.js/dist/video-js.css'

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // player is ready :)
    });
    EventService.listenEvent('videoStop', this.props.itemKey, () => this.player.pause() )
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
    EventService.unlistenEvent('videoStop',  this.props.itemKey)
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>	
        <div data-vjs-player style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, right: 0, bottom: 0 }}>
          <video ref={ node => this.videoNode = node } className="video-js"></video>
        </div>
      </div>
    )
  }
}
