import React, { Component } from "react"
import { Link } from "gatsby"
import AlbumService from "../services/AlbumService"
import DisplayService from "../services/DisplayService"
import loadingIcon from "../images/loader.svg"
import calendarIcon from '../images/menu/menu_calendar.png'
import "./media-detail.css"

class MediaDetail extends Component {
  constructor(props) {
    super(props)

    this.lastProcessedKey = null

    this.state = {
      isLoading: true,
      albumData: []
    }
  }

  async componentDidMount() {
    this.albumList = await AlbumService.getAlbums()
    if (this.props.selectedAlbum) this.srcAlbumInfo = AlbumService.extractIdData(this.props.selectedAlbum)
    const albumData = await this.processKey()
    this.setState({ 
      albumData,
      isLoading: false
    })
  }

  async componentDidUpdate() {
    if (this.lastProcessedKey === this.props.selectedMedia) return
    const albumData = await this.processKey()
    this.setState({ 
      albumData,
      isLoading: false
    })
  }

  async processKey() {
    if (!this.props.selectedMedia) return []
    this.lastProcessedKey = this.props.selectedMedia
    const split = this.props.selectedMedia.split('/')
    const day = split[split.length - 1].substr(0, 8)
    const data = await AlbumService.getDayAlbumItems(day)
    let albumData = data
      .filter(item => item.key === this.props.selectedMedia && item.albumId !== this.props.selectedAlbum)
      .map(item => {
        const albumDetailAr = this.albumList.filter(album =>  item.albumId === album.albumId)
        if (albumDetailAr.length) return albumDetailAr[0]
        return null
    })
    albumData = albumData.filter(item => item !== null) // extra validity that referenced album actually exists
    return albumData
  }

  render() {
    let content = <div className="loader">
      <img src={loadingIcon} alt="loading" />
    </div>

    if (!this.state.isLoading) {
      /*
      if (!this.state.albumData.length) {
        return <></>
      }
      */

      content = []
  
      // CALENDAR DATA
      if (this.props.selectedAlbum && (this.srcAlbumInfo.type !== 'EVENT' || this.srcAlbumInfo.startDate.getTime() !== this.srcAlbumInfo.endDate.getTime())) {
        // const split = this.props.selectedMedia.split('/')
        // const dateStr = split[split.length - 1].substr(0, 8)
        content.push(<div key="det_date" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Link exact="true" to={`/day/${DisplayService.toYYYYMMDD(DisplayService.getDateFromKey(this.props.selectedMedia))}`} activeClassName="active" title="Visiter la page du calendrier">
            <img src={calendarIcon} alt='calendar icon' style={{ width: '2rem' }} />
          </Link>
          <span style={{ fontWeight: 500, lineHeight: 1.8 }}>{DisplayService.getFormattedDateFromKey(this.props.selectedMedia)}</span>
        </div>)
      }

      // EVENT ALBUMS DATA
      if (this.state.albumData.filter(item => item.type === 'EVENT').length) {
        content.push(<div key="det_event">
          {this.state.albumData
          .filter(item => item.type === 'EVENT')
          .map(item => ({ ...item, Name: item.albumId.split('|')[2] }))
          .sort((a,b) => {
            if (a.Name < b.Name) return -1
            if (a.Name > b.Name) return 1
            return 0
          })
          .map((item, index) => {
            const albumData = AlbumService.extractIdData(item.albumId)
            return <a 
                key={`det_event_${item.albumId}`}
                href={`/album/${encodeURIComponent(item.albumId)}`}
                style={{ display: 'block' }}
              >
                {albumData.name}
              </a>
          }
          )}</div>)
      }

      // OTHER ALBUMS DATA
      if (this.state.albumData.filter(item => item.type === 'OTHER').length) {
        content.push(<div key="det_other">
          {this.state.albumData
          .filter(item => item.type === 'OTHER')
          .map(item => ({ ...item, Name: item.albumId.split('|')[2] }))
          .sort((a,b) => {
            if (a.Name < b.Name) return -1
            if (a.Name > b.Name) return 1
            return 0
          })
          .map((item, index) => {
            const albumData = AlbumService.extractIdData(item.albumId)
            return <a 
                key={`det_other_${item.albumId}`} 
                href={`/album/${encodeURIComponent(item.albumId)}`}
                style={{ display: 'block' }}
              >
                {albumData.name}
              </a>
          }
          )}</div>)
      }
      
      // PEOPLE ALBUMS DATA
      if (this.state.albumData.filter(item => item.type === 'PEOPLE').length) {
        content.push(<div key="det_people">
          {(this.props.selectedAlbum && this.props.selectedAlbum.split('|')[1] === 'PEOPLE') ? <span style={{ fontStyle: 'italic' }}>avec </span> : ''}
          {this.state.albumData
          .filter(item => item.type === 'PEOPLE')
          .map(item => ({ ...item, Name: item.albumId.split('|')[2] }))
          .sort((a,b) => {
            if (a.Name < b.Name) return -1
            if (a.Name > b.Name) return 1
            return 0
          })
          .map((item, index, arr) => {
            const separator = index ? (index === arr.length - 1 ? ' & ' : ', ') : ''
            return <span key={`det_people_${item.albumId}`}>
                {separator}
                <a 
                  href={`/album/${encodeURIComponent(item.albumId)}`}
                >
                  {item.Name}
                </a>
              </span>
          }
          )}</div>)
      }

      if (!content.length) {
        return <></>
      }
    }

    return (
      <div className="media-detail no-select">
        <div className="media-detail-inner">{content}</div>
      </div>
    )
  }
}

export default MediaDetail
