import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import CookieService from "../services/CookieService"
import AlbumService from "../services/AlbumService"
import DisplayService from "../services/DisplayService"
import UserService from "../services/UserService"
import AppStore from "../services/AppStore"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ViewSelector from "../components/view-selector"
import MediaDisplay from "../components/media-display"
import MediaDetail from "../components/media-detail"

// import inProgressIcon from '../images/in-progress.png'

Amplify.configure(config)

class AlbumPage extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false
    this.selectedAlbum = null
    this.state = {
      loading: true,
      albumName: "",
      albumDescription: "",
      itemList: [],
      galleryMode: false,
      selectedMedia: ''
    }

    this.handleToggleGalleryMode = this.handleToggleGalleryMode.bind(this)
    this.deleteAction = this.deleteAction.bind(this)
    this.setToGallery = this.setToGallery.bind(this)
    this.selectMedia = this.selectMedia.bind(this)
  }

  async handleToggleGalleryMode() {
    AppStore.set("ALBUM-PAGE-GALLERY-MODE", !this.state.galleryMode)
    this.setState({
      selectedMedia: this.state.itemList.length ? this.state.itemList[0].key : '',
      galleryMode: !this.state.galleryMode 
    })
  }

  async deleteAction(itemKey) {
    const conf = window.confirm(
      "La confirmation que vous voulez enlever cette photo de l'album (vous n'effacerez pas la ohoto)."
    )
    if (conf) {
      await AlbumService.removeAlbumItem(itemKey, this.selectedAlbum)
      this.setState( {
        itemList: this.state.itemList.filter(item => item.key !== itemKey)
      })
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    this.userInfo = await UserService.getUserInfo()
    this.selectedAlbum = this.props["*"]
    let galleryMode = AppStore.get("ALBUM-PAGE-GALLERY-MODE")
/*
    if (this._isMounted) {
      this.setState({
        galleryMode
      })
    }
*/
    this.albums = await AlbumService.getAlbums()
    this.albumItems = await AlbumService.getSortedAlbumItems(this.selectedAlbum)
    this.albumInfo = this.albums.find(album => album.albumId === this.selectedAlbum)

    if (this._isMounted) {
      this.setState({
        galleryMode,
        loading: false,
        albumName: this.albumInfo.name,
        albumDescription: this.albumInfo.description,
        itemList: this.albumItems,
        selectedMedia: this.albumItems.length ? this.albumItems[0].key : ''
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  selectMedia(mediaKey) {
    this.setState({ selectedMedia: mediaKey })
  }

  async setToGallery(mediaKey) {
    AppStore.set("ALBUM-PAGE-GALLERY-MODE", true)
    this.setState({
      selectedMedia: mediaKey,
      galleryMode: true
    })
  }

  getAlbumDescriptionBlock() {
    let result = []
    if (!this.albumInfo) return result

    if (this.albumInfo.type === 'EVENT') {
      result.push(<div key="alb_date" style={{ fontWeight: 500 }}>
        {this.albumInfo.startDate !== this.albumInfo.endDate ? <span><span>{DisplayService.getFormattedDate(this.albumInfo.startDate)}</span><span style={{ fontWeight: 300 }}> - </span><span>{DisplayService.getFormattedDate(this.albumInfo.endDate)}</span></span> : <span>{DisplayService.getFormattedDate(this.albumInfo.startDate)}</span>}
        {<span style={{ color: '#aaaaaa', fontStyle: 'italic' }}> - {this.albumInfo.user} </span>}
      </div>)
      if (this.albumInfo.description) {
        result.push(<div key="alb_description">{this.albumInfo.description}</div>)
      }
    }

    if (this.albumInfo.type === 'OTHER' || this.albumInfo.type === 'PEOPLE') {
      if (this.albumInfo.description) {
        result.push(<div key="alb_description">{this.albumInfo.description} <span style={{ color: '#aaaaaa', fontStyle: 'italic' }}> - {this.albumInfo.user} </span></div>)
      }
    }


    
    if (!result.length) return result

    return <div className="album-detail-block-inner">{result}</div>    
  }

  render () {
    let itemList = this.state.itemList.map(item => ({
      ...item,
      id: item.key
    }))

    let canDeleteFromAlbum = false
    if ((this.selectedAlbum && this.userInfo && this.selectedAlbum.indexOf(this.userInfo.username) === 0)
      || (this.userInfo && +this.userInfo.attributes['custom:admin'] === 2)) {
        canDeleteFromAlbum = true // if this is 'my' album or I am super admin
      } 

    let albumInfoBlock = this.getAlbumDescriptionBlock()
    
    return (
      <Layout menu="ALBUM">
        <SEO title="Album Detail" />
        <div className="album-header">
          <h1>{this.state.albumName}</h1>
          {albumInfoBlock ? <div className="album-detail-block no-select">{albumInfoBlock}</div>: ''}
          <ViewSelector 
            pageIsLoading={this.state.loading}
            galleryMode={this.state.galleryMode} 
            handleToggleGalleryMode={this.handleToggleGalleryMode}  
          />
        </div>

        <MediaDisplay
          loading={this.state.loading}
          itemList={itemList}
          galleryMode={this.state.galleryMode}
          setToGallery={this.setToGallery}
          selectMedia={this.selectMedia}
          selectedAlbum={this.selectedAlbum}
          deleteAction={canDeleteFromAlbum ? this.deleteAction : null}
        />
        {this.state.galleryMode ? <MediaDetail selectedMedia={this.state.selectedMedia} selectedAlbum={this.selectedAlbum} /> : ''}
      </Layout>
      )
  }
}

export default withAuthenticator(AlbumPage, {
  includeGreetings: false,
})
