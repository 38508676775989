import React, { Component } from "react"
import Image from "../components/image"
import VideoPlayer from "../components/video-player"
import ImageGallery from "react-image-gallery"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import loadingIcon from "../images/loader.svg"
import errorImage from "../images/not-available.jpg"
import videoPlayIcon from '../images/icon-play.svg'
import EventService from '../services/EventService'
import SVG from "../components/SVG"
import AppStore from "../services/AppStore"
import UserService from '../services/UserService'
import MediaDetail from "../components/media-detail"


class MediaDisplay extends Component {
  constructor(props) {
    super(props)
    this._isMounted = false
    this.isFullscreen = false
    this.userInfo = null
    this.lastSelectedMedia = null

    this.state = {
      selectedKey: null,
      showFullscreenButton: true
    }


    this.handleImageClick = this.handleImageClick.bind(this)

    this.onGallerySlide = this.onGallerySlide.bind(this)
    this.renderFullscreenButton = this.renderFullscreenButton.bind(this)
    this.renderThumbInner = this.renderThumbInner.bind(this)
    this.renderItem = this.renderItem.bind(this)
  }

  async handleImageClick(evt) {
    evt.persist()
    AppStore.set("CALENDAR-DAY-PAGE-GALLERY-MODE", true)
    await this.props.setToGallery(evt.target.dataset.key)
    this.setState({
      selectedKey: evt.target.dataset.key,
      showFullscreenButton:
        evt.target.dataset.key.indexOf("/public/videos") < 0
    })
  }



  renderFullscreenButton (onClick, isFullscreen) {
    // overriding only to access the isFullscreen status     
    this.isFullscreen = isFullscreen
    return <button
        type="button"
        className="image-gallery-icon image-gallery-fullscreen-button"
        onClick={onClick}
        aria-label="Open Fullscreen"
      >
        <SVG strokeWidth={2} icon={isFullscreen ? 'minimize' : 'maximize'} />
      </button>
  }

  async componentDidMount () {
    this._isMounted = true
    this.userInfo = await UserService.getUserInfo()
  }

  renderItem(item) {
    const itemSrc = this.isFullscreen ? (item.fullscreen || item.original) : item.original;
    let aspectRatio = item.height / item.width
    let outerAspectRatio = 0.66

    const outerStyle = {
      position: 'relative',
      width: "100%",
    }
    if (this.isFullscreen) {
      outerStyle.height = `${window.innerHeight}px`
    } else {
      outerStyle.paddingBottom = `${Math.floor((outerAspectRatio * 10000) / 100)}%`
    }

    return (
      <div style={outerStyle}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            maxWidth: `${item.width}px`,
            maxHeight: `${item.height}px`,
            transform: 'translate(-50%, -50%)',
            display: (aspectRatio >= outerAspectRatio) ? 'block' : 'flex'
          }}>
            <div style={
              (aspectRatio > outerAspectRatio) ? 
              {
                height: "100%",
                backgroundSize: 'contain',
                backgroundPosition: '50%',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${encodeURI(itemSrc).replace('(', '\\(').replace(')', '\\)')})`
              }
              :
              {
              width: "100%",
              paddingBottom: `${Math.floor((aspectRatio * 10000) / 100)}%`,
              backgroundSize: 'contain',
              backgroundPosition: '50%',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${encodeURI(itemSrc).replace('(', '\\(').replace(')', '\\)')})`
            }}>
            </div>
          {(this.isFullscreen) ? <div style={{ position: 'absolute', bottom: '20px', left: 0, width: '100%' }}><MediaDetail selectedMedia={item.key} selectedAlbum={this.props.selectedAlbum} /></div> : ''}
          </div>
        {
          item.description && (
            <span className="image-gallery-description">
              {item.description}
            </span>
          )
        }
      </div>
    );
  }

  renderVideo(item) {
    let aspectRatio = item.height / item.width
    let outerAspectRatio = 0.6666
    const videoSrc = `//common.the-red-frog.com/public/videos/${item.key.replace(
      "public/",
      ""
    )}`

    const videoJsOptions = {
      itemKey: item.key,
      autoplay: false,
      controls: true,
      sources: [
        {
          src: videoSrc,
          type: "video/mp4",
        },
      ],
      poster: `//common.the-red-frog.com/public/preview/${item.key
        .replace("public/", "")
        .replace(".mp4", ".jpg")}`,
    }

    return (
      <div
        style={{
          position: 'relative',
          width: "100%",
          paddingBottom: `${Math.floor((outerAspectRatio * 10000) / 100)}%`
        }}
      >
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            maxWidth: `${item.width}px`,
            maxHeight: `${item.height}px`,
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden'
          }}>
            <div style={
              (aspectRatio > outerAspectRatio) ? 
              {
                height: "100%"
              }
              :
              {
              width: "100%",
              paddingBottom: `${Math.floor((aspectRatio * 10000) / 100)}%`
            }}>
              <VideoPlayer {...videoJsOptions} />
            </div>
          </div>

        
      </div>
    )
  }

  renderThumbInner(item) {
    let aspectRatio = item.height / item.width
    const videoMode = (item.thumbnail.indexOf('/videos/') > -1)
    let videoMark = ''
    if (videoMode) {
      videoMark = <div className='video-mark'><img src={videoPlayIcon} alt="play video" /></div>
    }
    return (
      <div className="image-gallery-thumbnail-inner">
        <div style={{
          width: "100%",
          paddingBottom: `${Math.floor((aspectRatio * 10000) / 100)}%`,
          backgroundSize: 'contain',
          backgroundPosition: '50%',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          backgroundImage: `url(${encodeURI(item.thumbnail).replace('(', '\\(').replace(')', '\\)')})`
          }}>
            {videoMark}
        </div>
        {
          item.thumbnailLabel && (
            <div className="image-gallery-thumbnail-label">
              {item.thumbnailLabel}
            </div>
          )
        }
      </div>
    );
  }

  async onGallerySlide(nextIndex) {
    EventService.triggerEvent('videoStop')
    await this.props.selectMedia(this.props.itemList[nextIndex].key)

    this.setState({
      selectedKey: this.props.itemList[nextIndex].key,
      showFullscreenButton: this.props.itemList[nextIndex].key.indexOf("/public/videos") < 0
    })
  }

  render() {
    let content = null

    if (this.props.loading) {
      content = (
        <div className="loader" style={{ margin: '10rem' }}>
          <img src={loadingIcon} alt="loading" />
        </div>
      )
    } else {

      if (this.props.itemList.length === 0) {
        content = <div className="no-item">No item to display</div>
      } else {
        if (this.props.galleryMode) {
          let index = this.props.itemList.findIndex(
            el => el.key === this.state.selectedKey
          )
          if (index < 0) index = 0

          const images = this.props.itemList.map(item => {
            if (item.key.indexOf("/public/videos/") > 0) {
              return {
                key: item.key,
                original: `//common.the-red-frog.com/public/preview/${item.key
                  .replace("public/", "")
                  .replace(".mp4", ".jpg")}`,
                thumbnail: `//common.the-red-frog.com/public/thumb/${item.key
                  .replace("public/", "")
                  .replace(".mp4", ".jpg")}`,
                width: item.width,
                height: item.height,
                renderItem: this.renderVideo,
              }
            } else {
              return {
                key: item.key,
                original: `//common.the-red-frog.com/public/preview/${item.key.replace(
                  "public/",
                  ""
                )}`,
                thumbnail: `//common.the-red-frog.com/public/thumb/${item.key.replace(
                  "public/",
                  ""
                )}`,
                fullscreen: `//common.the-red-frog.com/public/full/${item.key.replace(
                  "public/",
                  ""
                )}`,
                width: item.width,
                height: item.height,
                renderItem: this.renderItem,
              }
            }
          })

          content = (
            <ImageGallery
              items={images}
              thumbnailPosition="left"
              showPlayButton={false}
              infinite={false}
              showFullscreenButton={this.state.showFullscreenButton}
              startIndex={index}
              onBeforeSlide={this.onGallerySlide}
              renderFullscreenButton={this.renderFullscreenButton}
              renderThumbInner={this.renderThumbInner}
              onErrorImageURL={errorImage}
              lazyLoad={true}
            />
          )
        } else {
          content = (
            <div className="latest-wrapper">
              {this.props.itemList.map(item => (
                <div className="latest-image-wrapper" key={`inn-${item.id}`}>
                  <div
                    className={`image-polaroid ${(this.props.albumToolIsOpen && item.albumSelection) ? 'selected' : ''}`}
                    key={`cont-${item.id}`}
                  >
                    <Image 
                      key={`img-${item.id}`} 
                      data={item} 
                      handleImageClick={this.handleImageClick}
                      user={this.userInfo} 
                      markAction={this.props.markAction} 
                      deleteAction={this.props.deleteAction}
                      albumItemsAction={this.props.albumItemsAction}
                      albumToolIsOpen={this.props.albumToolIsOpen}
                      selectedAlbums={this.props.selectedAlbums}
                      />
                  </div>
                </div>
              ))}
            </div>
          )
        }
      }

      
    }

  return <>{content}</>

  }
}

export default MediaDisplay
